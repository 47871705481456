import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
document.body.style.background = '#FAFAFA';
document.body.style.maxWidth = '7.5rem';
new Vue({
  created() {},
  mounted() {
    this.$nextTick(() => {
    });
  },
  render: h => h(App)
}).$mount("#app");

