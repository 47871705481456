<!-- <template>
  <div id="app" style="width: 7.5rem;margin: 0 auto;">
    <div ref="appContainer" class="pixi-container"></div>
  </div>
</template>

<script>
import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';

export default {
  name: 'home',
  components: {},
  data() {
    return {
      sizes: { width: 750, height: 1630 },
      app: null,
      mainScene: null,
      containers: [],
      lastY: 0,
      isDragging: false,
      dragStartIndex: 0,
      currentIndex: 0, // 新增的数据属性
      isScrolling: false, // 新增的数据属性，用于防止多次触发滚动
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initPixi();
    });
  },
  methods: {
    initPixi() {
      this.app = new PIXI.Application({
        width: this.sizes.width,
        height: this.sizes.height,
        backgroundColor: 0x1099bb,
        resizeTo: this.$refs.appContainer, // 使canvas尺寸适应容器
      });

      // 将Pixi应用添加到DOM中
      this.$refs.appContainer.appendChild(this.app.view);

      this.mainScene = new PIXI.Container();

      const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffa500, 0x00ffff, 0x4b0082, 0x8f00ff, 0x000000, 0x9932CD, 0x808080];
      const textColors = [0xffffff, 0xffffff, 0xffffff, 0x000000, 0x000000, 0xffffff, 0xffffff, 0xffffff, 0xffffff, 0xffffff]; // 确保文字颜色与背景对比度足够

      for (let i = 0; i < 10; i++) {
        const container = new PIXI.Container();
        container.y = -this.sizes.height * i; // 初始化位置为负值，确保只有第一个页面显示

        // 绘制背景矩形
        const rect = new PIXI.Graphics();
        rect.beginFill(colors[i]);
        rect.drawRect(0, 0, this.sizes.width, this.sizes.height);
        rect.endFill();
        container.addChild(rect);

        // 添加文字到Graphics中心
        const text = new PIXI.Text(`第${i + 1}页`, {
          fontFamily: 'Arial',
          fontSize: 100,
          fill: textColors[i], // 确保文字颜色与背景色不同
          align: 'center',
        });

        // 设置锚点为中心，并将文字定位到矩形中心
        text.anchor.set(0.5);
        text.x = this.sizes.width / 2;
        text.y = this.sizes.height / 2;
        rect.addChild(text); // 将文字添加到背景矩形中

        this.containers.push(container);
        this.mainScene.addChild(container);
      }

      this.app.stage.addChild(this.mainScene);

      this.app.stage.interactive = true;
      this.app.stage.on('pointerdown', this.onPointerDown);
      this.app.stage.on('pointermove', this.onPointerMove);
      this.app.stage.on('pointerup', this.onPointerUp);
      this.app.stage.on('pointerupoutside', this.onPointerUp);

      // 确保初始位置正确
      this.scrollContainers(0);
    },
    onPointerDown(event) {
      this.isDragging = true;
      this.lastY = event.data.global.y;
      this.dragStartIndex = this.currentIndex;
    },
    onPointerMove(event) {
      if (!this.isDragging) return;

      let newY = event.data.global.y;
      let deltaY = newY - this.lastY;

      if (this.currentIndex === 0 && deltaY > 0) {
        return;
      } else if (this.currentIndex === this.containers.length - 1 && deltaY < 0) {
        return;
      }

      this.containers.forEach(container => {
        container.y += deltaY;
      });
      this.lastY = newY;
    },
    onPointerUp() {
      this.isDragging = false;
      // 找出当前的容器索引
      const threshold = 100; // 滑动阈值
      const topContainer = this.containers[this.currentIndex];
      if (Math.abs(topContainer.y) > 0 && Math.abs(topContainer.y) < threshold) {
        // 回弹到当前页面
        this.scrollContainers(0);
      } else {
        if (topContainer.y < -threshold) {
          // 向上翻页
          this.scrollContainers(1);
        } else if (topContainer.y > threshold) {
          // 向下翻页
          this.scrollContainers(-1);
        }
      }
    },
    scrollContainers(direction) {
      if (this.isScrolling) return;
      this.isScrolling = true;

      const targetIndex = this.currentIndex + direction;

      // 防止越界
      if (targetIndex < 0 || targetIndex >= this.containers.length) {
        this.isScrolling = false;
        return;
      }

      this.currentIndex = targetIndex;

      // 使用 GSAP 平滑滚动到目标位置
      gsap.to(this.containers, {
        duration: 0.5, // 动画持续时间（秒）
        y: (index) => index * this.sizes.height - this.currentIndex * this.sizes.height,
        ease: 'power2.out', // 缓动效果
        onComplete: () => this.isScrolling = false // 动画完成后解锁
      });
    },
  },
  beforeDestroy() {
    if (this.app) {
      this.app.stage.off('pointerdown', this.onPointerDown);
      this.app.stage.off('pointermove', this.onPointerMove);
      this.app.stage.off('pointerup', this.onPointerUp);
      this.app.stage.off('pointerupoutside', this.onPointerUp);
      this.app.destroy(true, { children: true });
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pixi-container {
  width: 7.5rem;
  height: 100vh;
  overflow: hidden;
  position: relative;
  touch-action: none; /* 禁用浏览器默认的手势行为 */
}
</style> -->

<template>
  <div ref="canvasContainer" class="canvas-container"></div>
</template>

<script>
import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';

export default {
  name: 'PosterFlip',
  data() {
    return {
      app: null,
      poster1: null,
      poster2: null,
      posterContainer: null,
      isDragging: false,
      startY: 0,
      moveY: 0,
      currentPosterIndex: 0,
    };
  },
  mounted() {
    this.initPixiApp();
  },
  methods: {
    initPixiApp() {
      this.app = new PIXI.Application({ width: 375, height: 800 });
      this.$refs.canvasContainer.appendChild(this.app.view);

      this.poster1 = PIXI.Sprite.from(require('./path/to/poster1.png'));
      this.poster1.width = this.app.screen.width;
      this.poster1.height = this.app.screen.height;
      this.poster1.position.set(0, 0);
      // 添加文字到Graphics中心
      const text1 = new PIXI.Text(`第1页`, {
        fontFamily: 'Arial',
        fontSize: 100,
        fill: '0xff0000',
        align: 'center',
      });

      // 设置锚点为中心，并将文字定位到矩形中心
      text1.anchor.set(0.5);
      text1.x = this.app.screen.width / 1.2;
      text1.y = this.app.screen.height / 2;
      this.poster1.addChild(text1);

      this.poster2 = PIXI.Sprite.from(require('./path/to/poster2.png'));
      this.poster2.width = this.app.screen.width;
      this.poster2.height = this.app.screen.height;
      this.poster2.position.set(0, this.app.screen.height);
      // 添加文字到Graphics中心
      const text2 = new PIXI.Text(`第2页`, {
        fontFamily: 'Arial',
        fontSize: 100,
        fill: '0xff0000',
        align: 'center',
      });

      // 设置锚点为中心，并将文字定位到矩形中心
      text2.anchor.set(0.5);
      text2.x = this.app.screen.width / 1.2;
      text2.y = this.app.screen.height / 2;
      this.poster2.addChild(text2);

      this.poster3 = PIXI.Sprite.from(require('./path/to/poster1.png'));
      this.poster3.width = this.app.screen.width;
      this.poster3.height = this.app.screen.height;
      this.poster3.position.set(0, this.app.screen.height * 2);
      // 添加文字到Graphics中心
      const text3 = new PIXI.Text(`第3页`, {
        fontFamily: 'Arial',
        fontSize: 100,
        fill: '0xff0000',
        align: 'center',
      });

      // 设置锚点为中心，并将文字定位到矩形中心
      text3.anchor.set(0.5);
      text3.x = this.app.screen.width / 1.2;
      text3.y = this.app.screen.height / 2;
      this.poster3.addChild(text3);

      this.posterContainer = new PIXI.Container();
      this.posterContainer.position.set(0, 0);
      this.posterContainer.width = this.app.screen.width;
      this.posterContainer.height = this.app.screen.height * 3;
      this.posterContainer.addChild(this.poster1, this.poster2, this.poster3);

      this.app.stage.addChild(this.posterContainer);

      this.addInteraction();
    },
    addInteraction() {
      this.app.stage.interactive = true;
      this.app.stage.on('pointerdown', this.onPointerDown);
      this.app.stage.on('pointermove', this.onPointerMove);
      this.app.stage.on('pointerup', this.onPointerUp);
    },
    onPointerDown(event) {
      this.isDragging = true;
      this.startY = event.data.global.y;
      this.moveY = 0;
    },
    onPointerMove(event) {
      if (!this.isDragging) return;

      const moveY = event.data.global.y - this.startY;
      if (this.currentPosterIndex === 0 && moveY > 0) {
        return;
      } else if (this.currentPosterIndex === this.posterContainer.children.length - 1 && moveY < 0) {
        return;
      }
      this.posterContainer.position.y = (-this.currentPosterIndex * this.app.screen.height) + moveY;
      this.moveY = moveY;
    },
    onPointerUp() {
      this.isDragging = false;
      const threshold = 100; // 滑动阈值
      if (Math.abs(this.moveY) > threshold) {
        if (this.moveY < 0) { // 向上滑动
          if (this.currentPosterIndex < this.posterContainer.children.length - 1) {
            this.currentPosterIndex++;
          }
        } else { // 向下滑动
          if (this.currentPosterIndex > 0) {
            this.currentPosterIndex--;
          }
        }
        gsap.to(this.posterContainer, { duration: 0.3, y: -this.currentPosterIndex * this.app.screen.height });
      } else { // 滑动距离小于100像素且不为0，复位
        if (this.moveY !== 0) {
          gsap.to(this.posterContainer, { duration: 0.3, y: -this.currentPosterIndex * this.app.screen.height });
        }
      }
    },
  },
};
</script>

<style scoped>
.canvas-container {
  width: 7.5rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>